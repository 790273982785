@import '/src/styles/variables.scss';

.update-status-modal {
    position: absolute;
    background: #fff;
    box-shadow: 0 0 .3rem rgba(0, 0, 0, 0.2);
    box-sizing: inherit;
    border-radius: 1.75rem;
    padding: 3rem;
    max-width: 40rem;
    max-height: 35rem;
    overflow: scroll;
    margin-left: 0 !important;
    width: 100% !important;
    overflow: hidden;
  }

.update-status-select{
  width: 50%;
}