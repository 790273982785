page-not-found {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    display: grid;
    gap: 1rem;
    button{
        width: 50%;
        margin: auto !important;
    }
  }

  page-not-found-text{
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
