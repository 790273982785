@import '/src/styles/variables.scss';

benefits-wrapper {
    display: flex;
    flex-flow: row;
    margin: 0;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin: 0.5rem;
    }
}

benefits-title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
}

benefits-title::after {
        display: block;
        content: '';
        border-bottom: solid 3px #{$color-pink-highlight};
        margin-top: 1.25rem;
        margin-bottom: 1rem;
        width: 2.5rem;
}

benefits-data {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: 0;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin: 0.5rem;
        max-width: 100%;
    }
}

benefits-media {
    display: flex;
    flex-flow: column;
    margin: 0;
    align-items: center;
    width: 100%;
    img{
        border-radius: 5px;
        flex-grow: 0;
        flex-basis: 50%;
        margin-bottom: 1rem;
        max-width: 400px;
        @media only screen and (max-width: #{$responsive-width}) {
            width: inherit;
        }
    }
    @media only screen and (max-width: #{$responsive-width}) {
        margin: auto;
    }
}

card-data {
    display: flex;
    flex-flow: column;
    margin: 2rem 2rem 0 2rem;
    cursor: pointer;
    padding:  0 24px;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin: 0.5rem;
        width: 100%;
    }
}

card-data-selected {
    display: flex;
    flex-flow: column;
    margin: 2rem 2rem 0 2rem;
    border: solid 1.5px #B0B0B0;
    border-radius: 10px;
    padding: 10px 24px;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin: 0.5rem;
    }
}

card-title {
    font-weight: bold;
    font-size: 1.18rem;
    line-height: 3rem;
    border-bottom: solid 1px #EEEEEE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

card-description {
    font-size: 1rem;
}

