@import '/src/styles/variables.scss';

.opco-button-grid-item {
    padding: 1.5rem .5rem;
    margin: .5rem;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.25s ease-out;
    align-content: center;
    display: grid;
  }
  
  .opco-button-grid-item:hover{
    box-shadow: 16px 14px 17px 0 rgba(10, 10, 10, 0.22);
    transform: translateX(-5px) translateY(-5px);
  }
  
  .opco-button-icon{
    margin: auto;
    max-width: 5rem;
    max-height: 5rem;
  }
  
  
  opco-button-label{
    text-align: center;
    font-size: 1.25rem !important;
    padding-top: .5rem;
    font-weight: 700;
  }
