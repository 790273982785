@import '/src/styles/variables.scss';

.warning {
    border: .188rem solid #{$color-grey-border};
    border-radius: .5rem;
    margin: 1.5rem 0;
    align-items: center;
    background: #fff;
    padding: 1rem;
    display: flex;
}
    
.iconContainer {
    display: flex;
    align-self: flex-start;
}

.warningIcon {
    fill: #{$color-pink-highlight};
    height: 2.5rem;
    width: 2.5rem;
    transform: rotate(20deg);
    padding-right: 1rem;
}

info-span {
    font-weight: 300;
}

pink-span {
    color: #{$color-pink-highlight};
    font-weight: 600;
}

black-span {
    color: #000;
    font-weight: 600;
}

  