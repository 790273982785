@import '../styles/variables.scss';

navbar-link {
    a {
        font-size: 1.25rem;
        font-weight: bold;
        text-decoration: none;
        color: #000000;
    }
    a:visited {
        color: #000000;
    }
    a::after {
        display: block;
        content: '';
        border-bottom: solid 3px #{$body-bgcolor};
    }
    a:hover::after {
        display: block;
        content: '';
        border-bottom: solid 3px #{$color-pink-highlight};
    }
}

