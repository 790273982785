@import '/src/styles/variables.scss';

.faq-container {
    margin-bottom: 3rem;
    margin-top: 5rem;
    @media only screen and (max-width: #{$mobile-width}) {
        margin-top: 2rem;
    }
}

.header {
    margin-top: 2rem;
}

.sub-text {
    color: #{$color-blue-highlight};
    font-weight: 700;
    cursor: pointer;
}
