
@import '/src/styles/variables.scss';

confirm-title {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.75rem;
}

confirm-title::after {
        display: block;
        content: '';
        border-bottom: solid 3px #{$color-pink-highlight};
        margin-top: 1.25rem;
        margin-bottom: 1rem;
        width: 2.5rem;
}