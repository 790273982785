@import './variables.scss';

card-container {    
    background: #{$card-background};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    display: flex;
    flex-flow: column;
    height: 92%;
    font-size: 0.9rem;
}

card-container:hover {
    background: #{$card-background-hover};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}
.subheading {
    font-weight: bold;
    font-size: 1.1rem;
}


card-badge {
    padding-right: 0.5rem;
}

card-header {
    font-size:1.75rem;
    font-weight: bold;
}

card-heading {
    font-size:1.5rem;
    font-weight: bold;
    line-height:1.5rem;
    align-self: center;

}
card-heading::after {
    display: block;
    content: '';
    border-bottom: solid 3px #{$color-pink-highlight};
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    width: 2.5rem;
}

card-subheading {
    font-size:1.25rem;
    font-weight: bold;
    line-height:1.5rem;
    align-self: center;
    display: flex;
    align-items: center;

}
card-subheading::after {

    content: '';
    margin-top: 0.25rem;
    width: 2.5rem;
}

card-details {
    margin-top: 1.5rem;
    display: block;
}

card-content {
    display:flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

card-row {
    display:flex;
    flex-direction: row;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

card-item-left {
    display: flex;
    flex-grow: 1;
    padding-right: 0.2rem;
    justify-content: flex-start;
}

card-item-right {
    display: flex;
    justify-content: flex-end;
}

card-item-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
