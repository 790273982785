@import '/src/styles/variables.scss';

contact-details{
    margin: 1rem 0;
    width: 100%;
}

main-container{ 
    margin: 0 6rem;

    @media only screen and (max-width: #{$mobile-width}) {
        margin: 0 2rem;
    } 
}