@import '../../styles/variables.scss';

footer {

    display: flex;
    flex-flow: column;
    padding-bottom: 2rem;
}
footer-content {
    margin-top: 2rem;   
}
.footerLogo {
    width: 10rem;
    height: 2.5rem;
}

footer-text {
    font-size: smaller;
    color: white;
}

footer-link-row {
    display: flex;
    flex-flow: row;

    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: column wrap;
    }    

}
footer-links {
    display: flex;
    flex: 1;
    padding-top: 1rem;
    font-size: 0.9rem;
    @media only screen and (max-width: #{$responsive-width}) {
        padding-bottom: 1rem;
    }  
}

footer-links ul {
    color: white;
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
}

footer-links ul li {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

footer-links li a {
    text-decoration: none;
    color: white;
} 

footer-links li a:visited {
    text-decoration: none;
    color: white;
}

footer-links li a:hover {
    color: #{$color-pink-highlight};
}

social-links ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
}

social-links ul li {
    display: inline-block;
    padding: 0.5rem;
}
