@import '/src/styles/variables.scss';

video-wrapper {
    justify-content: center;
    display: flex;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 5rem 0;
    width: 100%;
    @media only screen and (max-width: #{$responsive-width}) {
        // flex-flow: row wrap;
        margin: 3rem 0;
        width: 100%;
    }
}

video-window {
    display: flex;
    flex-flow: column;
    // align-items: center;
    width: 75%;
    @media only screen and (max-width: #{$responsive-width}) {
        width: 100%;
    }
}

video-title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding-bottom: 1rem;
    text-align: center;
}

video-subtitle {
    font-size: 1.375rem;
    line-height: 1.75rem;
    padding-top: 1rem;
    margin: 0 auto;
    display: grid;
    text-align: start;
    @media only screen and (max-width: #{$responsive-width}) {
        font-size: 1rem;
    }
    a {
        color: #{$color-blue-highlight};
        font-weight: 600;
        text-decoration: underline;
        margin-bottom: 1rem;
        @media only screen and (max-width: #{$responsive-width}) {
            margin-bottom: .25rem;
        }
    }
    a:visited {
        color: #{$color-blue-highlight};;
    }
}

.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    h2 {
        margin-top: 0rem;
    }
}
