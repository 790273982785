@import '/src/styles/variables.scss';

.chip {
    border-radius: .5rem;
    font-size: .875rem;
    height: 2rem;
    color: #fff !important;
    width: 7rem;
    justify-content: flex-start;
  }

.approved{
    background-color: #3F6A00 !important; 
}
.denied{
    background-color: #DD3730 !important;
}
.error{
    background-color: #BA1B1B !important;
}
.pending{
    background-color: #cbc9c9 !important;
}
.canceled{
    background-color: #505A5F !important;
}
.needs-review{
    background-color: #5D3FD3 !important; 
}