@import "/src/styles/variables.scss";

.topNav {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  padding-top: 0;
  padding-left: #{$margin-left};
  padding-right: #{$margin-right};
  background-color: #000;
  height: 45px;
  align-items: center;

  @media only screen and (max-width: #{$responsive-width}) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.topNav-link{
    color: #fff !important;
    font-size: 0.875rem;
    font-weight: bold;
}

.topNav-link:hover{
    text-decoration: underline;
}

