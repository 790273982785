@import './styles/variables.scss';
@import './styles/grid.scss';
@import './styles/link.scss';
@import './styles/fonts.scss';
@import './styles/card.scss';
@import './styles/base.scss';

:root { 
  scroll-behavior: smooth; 
}

body {
  margin: 0;
  font-family: #{$font-family};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #{$body-color};
  background: #{$body-bgcolor};
}

