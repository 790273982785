
dialog-title {
    display: flex;
    flex-grow: 1;
    font-weight: bold;
    font-size: 1.5rem;
    width: 100%;
    padding-top: 1rem;
}

dialog-button {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    width: 100%;
    font-size: 0.9rem;
    align-content: center;

}
