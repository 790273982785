@import '/src/styles/variables.scss';

.extra-padding-2 {
    padding-bottom: 2rem;
}

.details {
    margin: 2rem 5rem;
    detail-header{
        font-weight: 700;
    }
    ul {
        margin-top: 0;
        padding-left: 1.5rem;
    }
    li {
        margin-top: .25rem
    }
    @media only screen and (max-width: #{$responsive-width}) {
        margin: 0rem;
        padding: 1rem 20px;
    }
}

.button {
    display: flex;
    justify-content: center;
}

.safety-link {
    color: #{$color-blue-highlight} !important;
    font-weight: 600;
    text-decoration: underline;
}