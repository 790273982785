@import '../../../styles/variables.scss';

gen-detail {
    display:flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    align-items: flex-start;
    flex-flow: row;
    margin: 1rem;
    padding: 1.25rem;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: column;
        align-items: unset;
    }    
}

.center-column-padding {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    @media only screen and (max-width: #{$responsive-width}) {
        padding-left: 0;
        padding-right: 0;
    }  
}

.border-bottom {
    border-bottom: 2px solid #000000;
}

.highlight {
    background-color: #f8f8f8;
}

.center-item {
    @media only screen and (max-width: #{$responsive-width}) {
        order: -1;
    }   
}

.detail-header {
    padding-bottom: 1rem;
}

.sub-column {
    margin-top: 3.5rem;
    @media only screen and (max-width: #{$responsive-width}) {
        margin-top: .5rem;
    }   
}

.list-icon {
    padding-right: 0.5em;
}

.filter-pink{
    filter: invert(27%) sepia(72%) saturate(3931%) hue-rotate(329deg) brightness(96%) contrast(113%)
}

.total-cost-tooltip {
    min-width: fit-content;
}

detail-item-selection {
    font-size:1.75rem;
    font-weight: bold;
    padding-top:0.5rem;
}
detail-item-badge {
    padding-right: 0.5rem;
    padding-top: 0.5rem;
}

detail-column {
    display:flex;
    flex-basis: 33%;
    max-width: 33%;
    flex-flow:column; 
    margin-left: 0.5em;
    margin-right: 0.5em;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
    }    
}

detail-row {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    flex-flow: row;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    align-items: center;
}

detail-item-left {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
}

detail-item-right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

detail-item-full {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    flex-direction: column;
}