@import '/src/styles/variables.scss';

landing-page-main {
    display: flex;
    flex-flow: row;
    margin: 5rem 0rem 1rem 0rem;
    background-color: #fff;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin: 3rem 0.5rem 0.5rem 0.5rem;
    }
}

landing-page-title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
}

landing-page-subtitle {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
}

landing-page-image {
    flex-grow: 0;
    margin: auto 0;
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
    }
}

landing-page-window {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
    padding: 2rem;
    margin: 0 auto;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
        padding: 1rem;
    }
}

landing-page-opcos{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    margin: 2rem 0;
    text-align: center;
    justify-content: center;
}
