@import './variables.scss';

.background-highlight {
    background-color: #{$color-bg-highlight};
}

.discount-text {
    color: #{$color-pink-highlight};
}

.pink-highlight {
    color: #{$color-pink-highlight};
}

.blue-highlight {
    color: #{$color-blue-highlight};
}

small-font {
    font-size: 0.8rem;
}

light-text {
    color: #{$color-light-text};
}

.error-text {
    color: #{$color-error};
}

.normal-font {
    font-size: 1rem;
}
.large-font {
    font-size: 1.2rem;
}

.bold {
    font-weight: bold;
}

.thank-you-note {
    display: block;
    margin-top: 1.5rem;
    a {
        color: #0091DA;
        text-decoration: underline;
    }
    a:visited {
        color: #0091DA;
        text-decoration: underline;
    }
    a:active {
        color: #0091DA;
        text-decoration: underline;
    }
}

.headingImg {
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    height: auto;
    @media only screen and (max-width: #{$responsive-width}) {
        width: 100%;
        height: auto;
        min-width: 200px;
        max-width: 1000px;
        
    }
}

a {
    color: #{$color-pink-highlight};
    text-decoration: none;
}
a:visited {
    color: #{$color-pink-highlight};
    text-decoration: none;
}
a:active {
    color: #{$color-purple-highlight};
    text-decoration: none;
}

content-area {
    margin-left: #{$margin-left};
    margin-right: #{$margin-right};

    @media only screen and (max-width: #{$mobile-width}) {
        margin-left: #{$mobile-margin-left};
        margin-right: #{$mobile-margin-right};
    } 
}


.spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }