@import '/src/styles/variables.scss';

header-area {
    position: sticky;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    order: 1;
    flex-direction: column;
}

main-area {
    display: flex;
    flex-direction: column;
    order: 2;
    min-height: 79vh;
    padding-bottom: 2rem;
 
}

footer-color {
    display: flex;
    flex-direction: column;
    order: 3;
    background-color: black;
}
footer-area {
    margin-left: #{$margin-left};
    margin-right: #{$margin-right};
    
    @media only screen and (max-width: #{$mobile-width}) {
        margin-left: #{$mobile-margin-left};
        margin-right: #{$mobile-margin-right};
    }  
}


