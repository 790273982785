.extra-padding-2 {
    padding-bottom: 2rem;
}

.extra-padding-1 {
    padding-bottom: 1rem;
}

.align-header {
    margin-left: inherit;
    margin-right: inherit;
    padding-left: 20px;
    padding-right: 20px;
}