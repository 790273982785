@import '../styles/variables.scss';

grid-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

no-wrap {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
}

grid-row {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-left: 4rem;
    margin-right: 4rem;
    flex-flow: row;
    align-items: baseline;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin-left: 0rem;
        margin-right: 0rem;
    }    
}

grid-row-stretch {
    @extend grid-row;
    align-items: stretch;
}

grid-row-bottom-align {
    @extend grid-row;
    align-items: flex-end;  
}

grid-row-heading {
    @extend grid-row;
    align-items: flex-start;
  
}

grid-item-half {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
    margin-top: 1rem;
    margin-right: 2rem;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0;
    }    
}

grid-item-full {
    margin-top: 1rem;
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
    justify-content: center;
}

grid-item {
    margin: 1rem;
    flex-grow: 1;
    justify-content: left;
}

grid-item-sm {
    margin: 0.5rem;
    flex-grow: 0;
}

grid-item-third {
    flex-grow: 0;
    flex-basis: 33%;
    max-width: 33%;
    margin: 1em;

    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
    }    
}

grid-item-two-third {
    flex-grow: 0;
    flex-basis: 66%;
    max-width: 66%;
    margin: 1em;

    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
    }    
}

grid-row-spacer {
    flex-grow: 1;
    flex-basis: 100%;
    min-width: 100%;
    min-height: 2rem;

}