@import '/src/styles/variables.scss';

carousel-data {
    display: flex;
    flex-flow: row;
    margin: 1rem;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-flow: row wrap;
        margin: 0.5rem;
    }
}

carousel-title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
}

carousel-title::after {
        display: block;
        content: '';
        border-bottom: solid 3px #{$color-pink-highlight};
        margin-top: 1.25rem;
        margin-bottom: 1rem;
        width: 2.5rem;
}

carousel-image {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: #{$responsive-width}) {
        flex-basis: 100%;
        max-width: 100%;
    }
}

carousel-window {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
    min-height: 375px;
    padding: 1rem 2.5rem 1.25rem 2.5rem;
    h2 {
        margin-top: 0rem;
    }
    @media only screen and (max-width: #{$responsive-width}) {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0rem;
    }
    button{
        @media only screen and (max-width: #{$x-small-width}) {
            width: 100%;
        }
    }
}

