@import "/src/styles/variables.scss";

.navbar {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  flex-flow: column;
  background-color: #{$body-bgcolor};
  padding: 1em;
  padding-left: #{$margin-left};
  padding-right: #{$margin-right};

  @media only screen and (max-width: #{$responsive-width}) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

navbar-content {
  display: flex;
  flex-direction: row;
  padding-right: 2em;
  @media only screen and (max-width: #{$responsive-width}) {
    padding-right: 0.5rem;
  }
}

navbar-logo {
  flex-grow: 1;
}

.mainLogo {
  width: 10rem;
  height: 2.5rem;
}

navbar-link-group {
  display: flex;
  flex-direction: row;
}

navbar-link {
  padding: 0.15em;
  a {
    display: flex;
    border-bottom: solid 3px transparent;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    padding: 6px;
    border-radius: 0 !important;
  }
  a:hover{
    display: flex;
    border-bottom: solid 3px #{$color-pink-highlight} !important;
    background-color: transparent !important;
    border-radius: 0;
  }
}

.active {
  border-bottom: solid 3px #{$color-pink-highlight} !important;
}

.drawer .MuiPaper-root {
  min-width: 50%;
  padding: 1rem 2rem;
}