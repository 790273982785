@import '/src/styles/variables.scss';

.data-grid {
  margin: 3rem 0;
  &.MuiDataGrid-root{
    background-color: #fff !important;
    min-height: 30rem;
  }
}
.data-grid-header{
  font-weight: bold;
}

.data-grid-cell {
  cursor: pointer;
}

.data-grid-cell:focus {
  outline: none !important;
}


