@import '/src/styles/variables.scss';

main-container{ 
    margin: 0 6rem;

    @media only screen and (max-width: #{$responsive-width}) {
        margin: 0 2rem;
    } 
}

.question-column {
    margin: 1rem 0;
    display: block;
    padding-top: 0 !important;
}

sign-up-question {
    display: block;
    margin-bottom: 1rem;
}

.selectField {
    min-width: 20rem;
}

question-header {
    display: block;
    font-weight: bold;
    margin-bottom: .5rem;
}

.textfield{
    display: block !important;
    .MuiOutlinedInput-root{
        min-width: 16rem !important;
    }
}