@import '/src/styles/variables.scss';

.extra-padding-2 {
    padding-bottom: 2rem;
}

.align-header {
    margin-left: inherit;
    margin-right: inherit;
    padding: 0;
}

.pink-header {
    color: #{$color-pink-highlight};
}

.cards-container{
    margin: 0;
    display: flex;
    justify-content: center;
}

sign-up-card {
    padding: 4rem 2rem 2rem 2rem;
    margin: .5rem;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 10px;
    min-height: 18rem;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
}

h2 {
    margin-bottom: .25rem;
    margin-top: 0;
}

button-wrapper{
    display: flex;
    justify-content: center; 
    button {
        min-width: 14rem !important;
    }
}

.clock-icon{
    width: 1em;
    height: 1em;
    margin-right: .5rem;
}

.info-icon{
    width: 1rem;
    height: 1rem;
}

.tool-tip-button{
    cursor: pointer;
}

.form-container{
    padding: 1rem 0;
}

.terms-container{
    margin: 0rem 2rem;
}

.terms-checkbox {
    &.Mui-checked{
        color: #{$color-pink-highlight} !important;
    }
}

.terms-link{
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
    color: #{$color-blue-highlight};
    margin: 0px;
    width: fit-content;
}

.eSignature-label{
    font-weight: 700;
    color: #{$color-grey-text};
}
.eSignature-field{
    width: 100%;
}
