@import '/src/styles/variables.scss';

admin-section{
    border: 1px #F1F1F1 solid;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    margin: 1rem 0;
    display: flex;
}

admin-section-header {
    font-weight: bold;
    font-size: 18px;
}


admin-question {
    display: block;
    margin-bottom: 1rem;
}

admin-question-label {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: grey
}

admin-question-answer {
    display: block;
    font-weight: bold;
    margin-bottom: .5rem;
    font-size: 16px;

}

admin-error-message {
    display: block;
    font-weight: bold;
    margin-bottom: .5rem;
    font-size: 16px;
    color: #BA1B1B
}

admin-divider {
    height: 1px;
    background-color: #{$divider-color};
    margin-top: 0.5rem;
  }

.admin-button-wrapper{
    margin: 1rem 0 !important;
    align-items: center;
  }

